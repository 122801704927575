import { showNotification } from "@mantine/notifications";
import { useStore } from "lib/store";
import { useEffect, useState } from "react";
import { FiAlertCircle, FiX } from "react-icons/fi";
import { useSearchParams, useNavigate } from "react-router-dom";
import api from "utils/api";

const VerifyEmail: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setJwt, setIsAuthenticated } = useStore();
  const token = searchParams.get("t");
  const isCompany = searchParams.get("isCompany");
  const [verifying, setVerifying] = useState(true);
  const [err, setErr] = useState("");
  const [redirectCountDown, setRedirectCoundDown] = useState(5);
  const [verified, setVerified] = useState(false);

  const startRedirectTimer = () => {
    let interval = setInterval(() => {
      setRedirectCoundDown((countDown) => (countDown > 0 ? countDown - 1 : 0));
    }, 1000);
    return () => clearInterval(interval);
  };

  useEffect(() => {
    if (redirectCountDown < 1) {
      setIsAuthenticated(true);
      navigate("/leads");
    }
  }, [redirectCountDown, navigate, setIsAuthenticated]);

  useEffect(() => {
    let clearInterval: () => void;
    if (!token) navigate("/");
    let url = `${isCompany ? "company" : "lead"}/verify?t=${token}`;
    api
      .get(url)
      .then(({ data }) => {
        setVerified(true);
        setVerifying(false);
        if (data.token && isCompany) {
          localStorage.setItem("token", data.token);
          setJwt(data.token);
          clearInterval = startRedirectTimer();
        }
      })
      .catch((err: any) => {
        setErr(err.response.data.message);
        setVerifying(false);
        showNotification({
          title: "Fehler beim Verifizieren des Kontos",
          color: "red",
          icon: <FiX />,
          message: err.response.data.message,
        });
      });

    return () => {
      clearInterval && clearInterval();
    };
  }, [token, isCompany, navigate, setJwt]);

  return (
    <div className="min-h-screen -mt-32 flex items-center justify-center">
      <div
        style={{ maxWidth: "24rem" }}
        className="container bg-white md:mx-auto m-4 shadow rounded-lg p-4"
      >
        {verifying && (
          <div className="text-center">
            <FiAlertCircle className="mx-auto text-green-500 text-5xl" />
            <h3 className="text-xl font-heading mt-4">
              Verifying your account...
            </h3>
          </div>
        )}
        {verified && (
          <div className="text-center">
            <FiAlertCircle className="mx-auto text-green-500 text-5xl" />
            <h3 className="text-xl font-heading mt-4">
              Dein Konto wurde erfolgreich verifiziert.
            </h3>
          </div>
        )}
        {!verifying && err && (
          <div className="text-center">
            <FiAlertCircle className="mx-auto text-red-500 text-5xl" />
            <h3 className="text-xl font-heading mt-4">
              Konto konnte nicht verifiziert werden
            </h3>
          </div>
        )}
        {verified && isCompany && (
          <h3 className="text-center max-w-md mx-auto mt-2 mb-4 font-sans text-gray-800">
            Sie werden in
            <b className="text-red-500"> {redirectCountDown}</b> Sekunden zur
            Bewerber:innen-Seite weitergeleitet.
          </h3>
        )}
        {verified && !isCompany && (
          <h3 className="text-center max-w-md mx-auto mt-2 mb-4 font-sans text-gray-800">
            Wir leiten nun dein Profil an die passenden Unternehmen weiter.
            Diese nehmen bei Interesse im nächsten Schritt direkten Kontakt mit
            dir auf.
          </h3>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
