import create from "zustand";

export interface IStore {
  isAuthenticated: boolean;
  isAuthenticating: boolean;
  company: null | object;
  jwt: null | string;
  setJwt: (jwt: string) => void;
  setIsAuthenticated: (status: boolean) => void;
  setIsAuthenticating: (status: boolean) => void;
}

export const useStore = create<IStore>((set) => ({
  isAuthenticated: false,
  isAuthenticating: true,
  company: null,
  jwt: null,
  setIsAuthenticating(status: boolean) {
    set((state) => {
      return { ...state, isAuthenticating: status };
    });
  },
  setIsAuthenticated: (isAuthenticated: boolean) => {
    set((state) => {
      const newState = { ...state, isAuthenticated };
      if (isAuthenticated && state.isAuthenticating) {
        newState.isAuthenticating = false;
      }
      return newState;
    });
  },
  setCompany: (company: object) => {
    set((state) => ({ ...state, company }));
  },
  setJwt: (jwt: string) => {
    set((state) => ({ ...state, jwt }));
  },
}));
