import { Input, InputWrapper } from "@mantine/core";

export const FormInput: React.FC<{
  label: string;
  placeholder: string;
  value?: string | number | readonly string[] | undefined;
  name?: string;
  err?: string | boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
  type?: string;
  required?: boolean;
  invalid?: boolean | undefined | string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}> = (props) => {
  return (
    <InputWrapper
      required={props.required}
      label={props.label}
      error={props.err}
      className="w-full"
    >
      <Input
        icon={props.icon}
        variant="filled"
        disabled={props.disabled}
        placeholder={props.placeholder}
        radius="sm"
        type={props.type || "text"}
        name={props.name}
        onChange={props.onChange}
        value={props.value}
        onBlur={props.onBlur}
        invalid={Boolean(props.invalid)}
      />
    </InputWrapper>
  );
};

export default FormInput;
