import { useStore } from "lib/store";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

const protectedRoutes = ["/leads", "/billing", "/"];

const Auth: React.FC = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    setJwt,
    setIsAuthenticated,
    setIsAuthenticating,
    isAuthenticated,
    isAuthenticating,
  } = useStore();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setJwt(token);
      setIsAuthenticated(true);
    } else {
      setIsAuthenticating(false);
    }
  }, [setJwt, setIsAuthenticated, setIsAuthenticating]);

  useEffect(() => {
    if (isAuthenticating) return;
    if (isAuthenticated) {
      if (
        location.pathname === "/" ||
        location.pathname === "/login" ||
        location.pathname === "/signup"
      ) {
        navigate("/leads");
      }
    } else {
      if (protectedRoutes.includes(location.pathname)) {
        navigate("/ausbildung-signup");
      }
    }
  }, [isAuthenticated, isAuthenticating, location.pathname, navigate]);

  return <>{props.children}</>;
};

export default Auth;
