import { useEffect, useState } from "react";
import classNames from "classnames";
import LeadSignup from "components/lead-signup";
import BGHero from "assets/imgs/hero-bg.jpg";
import { Modal, Button, Group, Divider } from "@mantine/core";
import ModelTitle from "components/titles/model";

const fbBotUrl = `https://www.messenger.com/t/106891391921579`;

const Homepage: React.FC<{ iframe?: boolean }> = ({ iframe }) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    // setTimeout(() => setOpened(true), 3000);
  }, []);

  useEffect(() => {
    (function (d, s, id) {
      let js: any;
      let fjs: any = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/de_DE/sdk/xfbml.customerchat.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  return (
    <>
      <Modal
        opened={opened}
        centered
        onClose={() => setOpened(false)}
        title={<ModelTitle title="Mit unserem Facebook Bot fortfahren?" />}
      >
        <p>
          Klicke auf weiter, um die Anmeldung über unseren Facebook Bot zu
          starten.
        </p>
        <Divider variant="dashed" className="my-4" />
        <Group position="center">
          <Button className="bg-red-400 hover:bg-primary">
            <a
              href={fbBotUrl}
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              Weiter
            </a>
          </Button>
          <Button
            variant="outline"
            color="gray"
            onClick={() => setOpened(false)}
          >
            Zurück
          </Button>
        </Group>
      </Modal>
      <div
        style={{
          backgroundImage: `linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, .7)), ${
            iframe ? "" : `url(${BGHero})`
          }`,
        }}
        className={classNames(
          "bg-left bg-cover flex items-center justify-center",
          { "min-h-screen -mt-16": !iframe }
        )}
      >
        <div className="container mx-auto flex items-center justify-end">
          <div className="w-full md:w-3/5 flex items-center justify-end pt-14">
            <LeadSignup />
          </div>
        </div>
      </div>
    </>
  );
};

export default Homepage;
