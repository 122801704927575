import classNames from "classnames";

const Title: React.FC = ({ children }) => {
  return <h2 className="font-sans font-semibold text-lg mt-4">{children}</h2>;
};

const Link: React.FC<{ link: string }> = ({ link }) => {
  return (
    <a
      className="text-red-500 font-semibold"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      {link}
    </a>
  );
};

const Paragraph: React.FC<{ hasM?: boolean }> = ({ children, hasM }) => {
  return <p className={classNames({ "mt-4": hasM })}>{children}</p>;
};

const TermsAndConditions: React.FC = () => {
  return (
    <div className="relative overflow-hidden">
      <div className="max-w-7xl mx-auto px-8 py-8">
        <h1 className="text-gray-800 font-heading text-4xl mt-10">
          Allgemeine Geschäftsbedingungen zur Vermittlung von Bewerbern über
          www.handwerker-treff.com
        </h1>
        <div>
          <ol className="list-decimal text-gray-700 pl-5 mt-4">
            <li>
              <Title>Definitionen, Vertragsparteien</Title>
              <Paragraph>
                „Partner“ bezeichnet das Unternehmen, welches sich auf der
                Website <Link link="https://www.handwerker-treff.com" /> als
                „Unternehmen“ registriert und ein Benutzerkonto eingerichtet und
                diese Allgemeinen Geschäftsbedingungen zur Vermittlung von
                Bewerbern, durch Anklicken des Feldes „Ich akzeptiere die
                Allgemeinen Geschäftsbedingungen zur Vermittlung von Bewerbern
                der MoBerries GmbH” auf der Website{" "}
                <Link link="https://www.handwerker-treff.com" /> akzeptiert hat.
              </Paragraph>
              <Paragraph hasM>
                „MoBerries“ meint die MoBerries GmbH mit Sitz in der Schwedter
                Str. 9a, 10119 Berlin, Deutschland, die im Handelsregister des
                Amtsgerichts Berlin Charlottenburg unter der Nummer HRB 165716 B
                eingetragen ist.
              </Paragraph>
            </li>
            <li>
              <Title>
                Anwendungsbereich, Bewerbersuche und Bereitstellung von
                Bewerbern
              </Title>
              <Paragraph>
                2.1 Diese Allgemeinen Geschäftsbedingungen zur Vermittlung von
                Bewerbern („AGBs zur Bewerbervermittlung“) gelten
                ausschließlich. Entgegenstehenden oder von diesen AGBs zur
                Bewerbervermittlung abweichenden oder diese ergänzenden
                Bedingungen, insbesondere allgemeinen (Auftrags-) Bedingungen
                des Partners, wird hiermit widersprochen. Abweichungen oder
                Ergänzungen werden nur anerkannt, wenn MoBerries diesen
                ausdrücklich schriftlich zustimmt.
              </Paragraph>
              <Paragraph hasM>
                2.2 Diese AGBs zur Bewerbervermittlung gelten nur gegenüber
                Unternehmern, juristischen Personen des öffentlichen Rechts oder
                öffentlich-rechtlichen Sondervermögen im Sinne von § 310 Abs. 1
                BGB.
              </Paragraph>
              <Paragraph hasM>
                2.3 MoBerries stellt dem Partner über die Website{" "}
                <Link link="https://www.handwerker-treff.com" /> ein online
                verfügbares Benutzerkonto im Rahmen der MoBerries Plattform zur
                Verfügung, über welches der Partner u.a. seine Bewerbersuchen
                starten oder die von MoBerries zur Verfügung gestellten Bewerber
                einsehen kann.
              </Paragraph>
              <Paragraph hasM>
                2.4 Der Partner ist berechtigt, Bewerbersuchen gemäß Absatz 2.5
                zu starten, die von MoBerries nach Maßgabe dieser AGBs zur
                Bewerbervermittlung auszuführen sind. Es können mehrere
                Bewerbersuchen gleichzeitig aktiv sein.
              </Paragraph>
              <Paragraph hasM>
                2.5 Um eine Bewerbersuche zu starten, muss der Partner bei
                MoBerries einen Antrag auf Bewerbersuche unter Verwendung der
                relevanten Eingabemaske in seinem Benutzerkonto unter Angabe der
                Art und der Kategorie der dem Bewerber anzubietenden Stelle
                („Stelle“) und des Profils des angefragten Bewerbers
                („Stellenbeschreibung“) sowie aller weiteren zusätzlichen
                Anforderungen (falls vorhanden) übermitteln.
              </Paragraph>
              <Paragraph hasM>
                2.6 Nach Eingang des Antrags auf Bewerbersuche wird MoBerries
                für den Partner Personen suchen, die der Stellenbeschreibung und
                den weiteren Anforderungen (falls vorhanden) des Partners
                entsprechen. Die Suche beschränkt sich auf die gemeinsame Penta
                -MoBerries-Datenbank und alle in ihr erfassten Personen.
              </Paragraph>
              <Paragraph hasM>
                2.7 Die Ergebnisse der Bewerbersuche werden dem Partner von
                MoBerries im Anschluss an den Suchzeitraum zur Verfügung
                gestellt (jede Person, die dem Partner als in Betracht kommend
                genannt wird, im Folgenden als „Bewerber“ bezeichnet).
              </Paragraph>
              <Paragraph hasM>
                2.8 Der Partner hat die von MoBerries zur Verfügung gestellten
                Daten und Informationen über die Bewerber vertraulich zu
                behandeln, diese nicht an Dritte weiterzugeben und darf sie nur
                unter Einhaltung der geltenden Gesetze und nur für die in Absatz
                4 genannten Zwecke im Hinblick auf die jeweilig zu besetzende
                Stelle verwenden.
              </Paragraph>
            </li>
            <li>
              <Title>
                Annahme oder Ablehnung von Bewerbern durch den Partner
              </Title>
              <Paragraph>
                3.1 MoBerries wird dem Partner im Rahmen seines Benutzerkontos
                ermöglichen, die relevanten anonymisierten Daten und
                Informationen der entsprechend den Ergebnissen der Bewerbersuche
                in Betracht kommenden Bewerber einzusehen, woraufhin dieser die
                einzelnen Bewerber im Rahmen seines Benutzerkontos durch
                Anklicken des Feldes „Annehmen” „annehmen“ oder durch Anklicken
                des Feldes „Ablehnen“ „ablehnen“ kann.
              </Paragraph>
              <Paragraph hasM>
                3.2 Hat der Partner einen Bewerber durch Anklicken „angenommen“,
                wird MoBerries dem Partner daraufhin die vollständigen Daten des
                Bewerbers mitteilen, damit der Partner den betreffenden Bewerber
                kontaktieren und ihn für die Stelle in Betracht ziehen kann.
                Eine Weitergabe dieser Daten an Dritte (z.B. mit dem Partner
                verbundene Unternehmen) ist nicht gestattet. Mit Ausnahme der in
                Absatz 2.12 genannten Beschränkung für die in Absatz 4 genannten
                Zwecke, gilt Absatz 2.12 entsprechend.
              </Paragraph>
            </li>
            <li>
              <Title>Pflichten des Partners, Vertragsstrafe</Title>
              <Paragraph>
                4.1 Unentgeltlicher Abonnementplan ohne Monatsgebühr, Honorar
              </Paragraph>
              <Paragraph hasM>
                4.1.1 Der Partner hat MoBerries unverzüglich darüber zu
                informieren, wenn er eine Stelle an einen von MoBerries zur
                Verfügung gestellten und vom Partner gemäß Absatz 3.1
                angenommenen Bewerber vergeben hat. Hierbei hat er MoBerries den
                Namen des betreffenden Bewerbers und die Kategorie der besetzten
                Stelle zu nennen.
              </Paragraph>
              <Paragraph hasM>
                4.1.2 Lehnt der Partner einen von MoBerries zur Verfügung
                gestellten Bewerber im Sinne des Absatzes 4.1 ab oder nimmt der
                Partner diesen im Sinne des Absatzes 4.1 weder an noch lehnt er
                diesen ab und schließt er mit diesem Bewerber in Folge der
                Zurverfügungstellung gleichwohl einen Vertrag über ein
                unbefristetes oder befristetes Arbeitsverhältnis, hat der
                Partner MoBerries hierüber unverzüglich in Übereinstimmung mit
                den in Absatz 4.1.1 genannten Pflichten zu informieren.
              </Paragraph>
            </li>
            <li>
              <Title>Gebühren, Honorar und Zahlungskonditionen</Title>
              <Paragraph>
                5. Unentgeltlicher Abonnementplan ohne Monatsgebühr, Honorar
              </Paragraph>
              <Paragraph hasM>
                5.1 Hat der Partner einen unentgeltlichen Abonnementplan ohne
                Verpflichtung zur Zahlung einer Monatsgebühr gewählt und
                akzeptiert er einem von MoBerries zur Verfügung gestellten
                Bewerber, hat der Partner MoBerries für die Tätigkeit und
                Zurverfügungstellung dieses Bewerbers ein Honorar in der Höhe
                von EUR 30,- (in Worten: dreißig Euro).
              </Paragraph>
              <Paragraph hasM>
                5.2 Das Honorar fällt monatlich an und wird binnen fünf (5)
                Werktagen nach Inrechnungstellung durch MoBerries zur Zahlung
                fällig. Der Anspruch auf das Honorar entsteht auch dann, wenn
                der Abschluss des Interviews erst nach Beendigung des
                Vertragsverhältnisses zwischen MoBerries und dem Partner
                erfolgt, aber aufgrund der Tätigkeit und Zurverfügungstellung
                von MoBerries zustande kommt.
              </Paragraph>
            </li>
            <li>
              <Title>Laufzeit und Beendigung</Title>
              <Paragraph>
                6.1 Das Vertragsverhältnis zwischen MoBerries und dem Partner
                wird mit Anklicken des Feldes „Ich akzeptiere die Allgemeinen
                Geschäftsbedingungen zur Vermittlung von Bewerbern der MoBerries
                GmbH” auf der Website{" "}
                <Link link="https://www.handwerker-treff.com" /> durch den
                Partner wirksam und hat eine unlimitierte Laufzeit. Das
                Vertragsverhältnis kann von einer Partei mit einer
                Kündigungsfrist von einer (1) Woche in Textform (E-Mail)
                gekündigt werden.
              </Paragraph>
              <Paragraph hasM>
                6.2 Das Recht der Parteien zur außerordentlichen Kündigung aus
                wichtigem Grund bleibt unberührt. Jede außerordentliche
                Kündigung bedarf der Schriftform.
              </Paragraph>
            </li>
            <li>
              <Title>Vertraulichkeit</Title>
              <Paragraph>
                7.1 Die Parteien verpflichten sich, jegliche die jeweils andere
                Partei und ihr Unternehmen betreffenden Informationen, die nicht
                öffentlich bekannt sind und die ihr im Rahmen oder im
                Zusammenhang mit dem Vertragsverhältnis bekannt gegeben worden
                sind, streng vertraulich zu behandeln und Dritten nicht
                zugänglich zu machen, außer soweit dies für die Durchführung des
                Vertragsverhältnisses erforderlich ist.
              </Paragraph>
              <Paragraph hasM>
                7.2 Das Recht auf außerordentliche Kündigung gemäß Absatz 7.2
                bleibt unberührt.
              </Paragraph>
            </li>
            <li>
              <Title>Haftung und Gewährleistung</Title>
              <Paragraph>
                8.1 Außer in Fällen grober Fahrlässigkeit oder Vorsatz aufseiten
                von MoBerries, haftet MoBerries nicht für die Richtigkeit,
                Vollständigkeit oder den Inhalt von, oder in anderer Hinsicht
                für, Informationen und Angaben, die Bewerber MoBerries und/oder
                dem Partner zur Verfügung stellen.
              </Paragraph>
              <Paragraph hasM>
                8.2 Im Übrigen haftet MoBerries auf Schadensersatz – gleich aus
                welchem Rechtsgrund – im Rahmen der Verschuldenshaftung bei
                grober Fahrlässigkeit und Vorsatz. Bei einfacher Fahrlässigkeit
                haftet MoBerries nur für Schäden aus der Verletzung des Lebens,
                des Körpers oder der Gesundheit sowie für Schäden aus der nicht
                unerheblichen Verletzung einer wesentlichen Vertragspflicht
                (Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung
                des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung
                der Partner regelmäßig vertraut und vertrauen darf); in
                letzterem Fall der nicht unerheblichen Verletzung einer
                wesentlichen Vertragspflicht ist die Haftung von MoBerries auf
                den Ersatz des vorhersehbaren, typischerweise eintretenden
                Schadens begrenzt.
              </Paragraph>
            </li>
            <li>
              <Title>Werbung mit dem Partner als Referenzkunden</Title>
              <Paragraph>
                Für die Dauer des Vertragsverhältnisses gewährt der Partner
                MoBerries das Recht, den Namen und das Logo des Partners zu
                verwenden, um diesen als Referenzkunden zu benennen (z. B. auf
                der MoBerries Website und in Print- und/oder
                Online-Werbematerialien von MoBerries).
              </Paragraph>
            </li>
            <li>
              <Title>Sonstiges</Title>
              <Paragraph>
                10.1 Diese AGBs zur Bewerbervermittlung einschließlich der
                separaten Vereinbarungen über die Laufzeit und die gewählte
                Abonnementstufe stellen die gesamte Vereinbarung zwischen den
                Parteien in Bezug auf den Gegenstand dar und ersetzen jeden
                diesbezüglichen vorherigen Vertrag und/oder jede vorherige
                Vereinbarung, ob schriftlich oder mündlich, zwischen den
                Parteien.
              </Paragraph>
              <Paragraph hasM>
                10.2 Änderungen der Regelungen des Vertragsverhältnisses sowie
                dieser zwischen den Parteien vereinbarten AGBs zur
                Bewerbervermittlung, einschließlich dieses Absatzes 12.2, können
                nur einvernehmlich erfolgen und bedürfen für ihre Gültigkeit der
                Textform.
              </Paragraph>
              <Paragraph hasM>
                10.3 Diese AGBs zu Bewerbervermittlung wie auch das
                Vertragsverhältnis zwischen den Parteien unterliegen dem Recht
                der Bundesrepublik Deutschland, unter Ausschluss des
                Übereinkommens der Vereinten Nationen über Verträge über den
                internationalen Warenkauf (CISG).
              </Paragraph>
              <Paragraph hasM>
                10.4 Erfüllungsort und ausschließlicher Gerichtsstand für alle
                Streitigkeiten aus oder in Verbindung mit dem Vertragsverhältnis
                zwischen den Parteien ist Berlin, Deutschland. MoBerries ist
                jedoch auch berechtigt, jedes andere Gericht anzurufen, dessen
                Zuständigkeit nach dem Gesetz gegeben wäre. Zwingende
                gesetzliche Vorschriften, insbesondere zu ausschließlichen
                Zuständigkeiten, bleiben unberührt.
              </Paragraph>
              <Paragraph hasM>
                10.5 Sollten einzelne Bestimmungen dieser AGBs zu
                Bewerbervermittlung und/oder des Vertragsverhältnisses nicht
                wirksam sein, so wird dadurch die Wirksamkeit der restlichen
                Bestimmungen der AGBs zu Bewerbervermittlung bzw. des
                Vertragsverhältnisses nicht berührt. In einem solchen Fall gilt
                die unwirksame Bestimmung als durch eine wirksame Bestimmung
                ersetzt, welche der wirtschaftlichen Absicht der Parteien am
                nächsten kommt.
              </Paragraph>
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
