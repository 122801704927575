import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import {
  Button,
  Divider,
  Select,
  MultiSelect,
  Tooltip,
  Checkbox,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import { FiBriefcase, FiCheck, FiX } from "react-icons/fi";
import {
  FiCompass,
  FiMail,
  FiMapPin,
  FiPhone,
  FiUserCheck,
  FiAlertCircle,
} from "react-icons/fi";
import FormInput from "components/form-input";
import { showNotification } from "@mantine/notifications";
import api from "utils/api";
import { useSearchCity } from "hooks/search-city";
import { FaQuestion } from "react-icons/fa";

const dropdownOptions = [
  { label: "Hauptschule", value: "Hauptschule" },
  { label: "Realschule", value: "Realschule" },
  { label: "Gymnasium", value: "Gymnasium" },
  { label: "Kein Abschluss", value: "Kein Abschluss" },
];

const LeadSignup: React.FC = () => {
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [accountCreated, setAccountCreated] = useState(false);
  const [searchCity, setSearchCity] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [searchCityDebounced] = useDebouncedValue(searchCity, 200);
  const [currentCityDebounced] = useDebouncedValue(currentCity, 200);
  const [searchCityOptions] = useSearchCity(searchCityDebounced);
  const [currentCityOptions] = useSearchCity(currentCityDebounced);

  const fetchOccupationOptions = async () => {
    const { data } = await api.get("/lead/categories");
    setOccupationOptions(data?.categories || []);
  };

  const onSubmit = async (fields: any) => {
    const currCityObj = currentCityOptions.reduce((acc: any, curr: any) => {
      acc[curr.value] = curr;
      return acc;
    }, {});
    const searchCityObj = searchCityOptions.reduce((acc: any, curr: any) => {
      acc[curr.value] = curr;
      return acc;
    }, {});

    const searchCities = fields.searchCity.map(
      (city: any) => searchCityObj[city].label
    );
    const currentCity = currCityObj[fields.currentCity].label;

    try {
      await api.post("/lead/signup", {
        ...fields,
        searchCity: searchCities,
        currentCity,
        phoneNumber: fields.phone,
      });
      showNotification({
        color: "green",
        icon: <FiCheck />,
        message: "Konto wurde erfolgreich angelegt",
      });
      setAccountCreated(true);
    } catch (err: any) {
      showNotification({
        title: "Fehler beim Erstellen des Kontos",
        color: "red",
        icon: <FiX />,
        message: err.response.data.message,
      });
    }
  };

  useEffect(() => {
    fetchOccupationOptions();
  }, []);

  return (
    <div
      style={{ maxWidth: "24rem" }}
      className="container bg-white md:mx-auto m-4 shadow rounded-lg p-4"
    >
      {accountCreated && (
        <div className="text-center">
          <FiAlertCircle className="mx-auto text-green-500 text-5xl" />
          <h3 className="text-xl font-heading mt-4">
            Bitte bestätige abschließend noch dein Konto, indem du auf den Link
            klickst, den wir dir soeben via Email geschickt haben.
          </h3>
        </div>
      )}
      {!accountCreated && (
        <Formik
          initialValues={{
            name: "",
            professionLevel: "",
            email: "",
            phone: "",
            currentCity: "",
            searchCity: [],
            currentOccupation: [],
            checked1: false,
            checked2: false,
          }}
          validate={(values) => {
            const errors: Record<string, string | number> = {};
            if (!values.email) {
              errors.email = "Eingabe ist erforderlich";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Ungültige Email Adresse";
            }
            if (!values.name) errors.name = "Eingabe ist erforderlich";
            if (!values.professionLevel)
              errors.professionLevel = "Eingabe ist erforderlich";
            if (!values.phone) errors.phone = "Eingabe ist erforderlich";
            if (!values.currentCity)
              errors.currentCity = "Eingabe ist erforderlich";
            if (!values.searchCity.length)
              errors.searchCity = "Eingabe ist erforderlich";
            if (!values.currentOccupation.length)
              errors.currentOccupation = "Eingabe ist erforderlich";
            if (!values.checked1) errors.checked1 = "Eingabe ist erforderlich";
            if (!values.checked2) errors.checked2 = "Eingabe ist erforderlich";

            return errors;
          }}
          onSubmit={onSubmit}
        >
          {({
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            values,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <h1 className="font-heading text-3xl text-primary">
                  Ausbildung gesucht?
                </h1>
                <h2 className="font-sans font-semibold mt-3 text-sm text-gray-600">
                  Jetzt kostenlos registrieren und zahlreiche Ausbildungsplätze
                  in spannenden Unternehmen finden.
                </h2>
                <Divider my="sm" variant="dashed" />
                <FormInput
                  placeholder="Vollständiger Name"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  invalid={errors.name && touched.name}
                  required
                  label="Vollständiger Name"
                  err={touched.name && errors.name}
                  icon={<FiUserCheck />}
                />
                <Divider my="sm" variant="dashed" />
                <div className="flex flex-col md:flex-row">
                  <FormInput
                    placeholder="Email Adresse"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors.email && touched.email}
                    required
                    label="Email"
                    err={touched.email && errors.email}
                    icon={<FiMail />}
                  />
                  <Divider
                    my="sm"
                    variant="dashed"
                    className="md:hidden block"
                  />
                  <div className="hidden md:block p-2" />
                  <FormInput
                    placeholder="Telefonnummer"
                    type="phone"
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    invalid={errors.phone && touched.phone}
                    required
                    label="Telefonnummer"
                    err={touched.phone && errors.phone}
                    icon={<FiPhone />}
                  />
                </div>
                <Divider my="sm" variant="dashed" />
                <Select
                  error={errors.professionLevel && touched.professionLevel}
                  className="w-full"
                  placeholder="Abschluss"
                  name="professionLevel"
                  required
                  variant="filled"
                  label="Angestrebter Abschluss"
                  data={dropdownOptions}
                  icon={<FiAlertCircle />}
                  onChange={(data) => setFieldValue("professionLevel", data)}
                  onBlur={handleBlur}
                />
                <Divider my="sm" variant="dashed" />
                <div className="flex flex-col md:flex-row">
                  <Select
                    className="w-full"
                    placeholder="Wohnort"
                    name="currentCity"
                    required
                    variant="filled"
                    searchable
                    limit={5}
                    onSearchChange={setCurrentCity}
                    label="Aktueller Wohnort"
                    data={currentCityOptions}
                    icon={<FiCompass />}
                    onChange={(data) => setFieldValue("currentCity", data)}
                    onBlur={handleBlur}
                  />
                </div>
                <Divider my="sm" variant="dashed" />
                <MultiSelect
                  placeholder="Gewünschter Standort"
                  name="searchCity"
                  required
                  variant="filled"
                  searchable
                  limit={5}
                  onSearchChange={setSearchCity}
                  label={
                    <>
                      Gewünschte(r) Unternehmensstandort(e)
                      <Tooltip
                        label={
                          <p className="font-sans font-semibold">
                            Du kannst mehrere Standorte angeben, die für dich in
                            Frage kommen würden
                          </p>
                        }
                        withArrow
                        arrowSize={3}
                        position="bottom"
                      >
                        <div className="bg-primary p-1 -mb-1 ml-1 text-xs rounded-full text-white">
                          <FaQuestion />
                        </div>
                      </Tooltip>
                    </>
                  }
                  data={searchCityOptions}
                  icon={<FiMapPin />}
                  value={values.searchCity}
                  onChange={(data) => setFieldValue("searchCity", data)}
                  onBlur={handleBlur}
                />
                <Divider my="sm" variant="dashed" />
                <MultiSelect
                  error={errors.currentOccupation && touched.currentOccupation}
                  label={
                    <>
                      Gewünschter Beruf
                      <Tooltip
                        label={
                          <p className="font-sans font-semibold">
                            Du kannst mehrere Berufe angeben, die aktuell für
                            dich in Frage kommen würden
                          </p>
                        }
                        withArrow
                        arrowSize={3}
                        position="bottom"
                      >
                        <div className="bg-primary p-1 -mb-1 ml-1 text-xs rounded-full text-white">
                          <FaQuestion />
                        </div>
                      </Tooltip>
                    </>
                  }
                  name="currentOccupation"
                  required
                  variant="filled"
                  placeholder="Beruf wählen"
                  data={occupationOptions}
                  icon={<FiBriefcase />}
                  onChange={(data) => setFieldValue("currentOccupation", data)}
                  onBlur={handleBlur}
                />
                <Divider my="sm" variant="dashed" />
                <Checkbox
                  checked={values.checked1}
                  onChange={handleChange}
                  name="checked1"
                  label={
                    <p className="font-sans font-semibold text-gray-700">
                      Hiermit bestätige ich, dass meine Daten von{" "}
                      <a
                        href="http://handwerker-treff.com"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                      >
                        Handwerker-Treff.com
                      </a>{" "}
                      zum Zwecke der Jobvermittlung an Partnerunternehmen
                      weitergeleitet werden dürfen.
                    </p>
                  }
                />
                <Divider my="sm" variant="dashed" />
                <Checkbox
                  checked={values.checked2}
                  onChange={handleChange}
                  name="checked2"
                  label={
                    <p className="font-sans font-semibold text-gray-700 break-all">
                      Hiermit bestätige ich die allgemeinen Geschäftsbedingungen{" "}
                      <a
                        href="https://www.moberries.com/de/companies/terms"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                      >
                        https://www.moberries.com/de/companies/terms
                      </a>{" "}
                      und den Datenschutz{" "}
                      <a
                        href="https://www.moberries.com/de/privacy"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary"
                      >
                        https://www.moberries.com/de/privacy
                      </a>
                      .
                    </p>
                  }
                />
                <Divider my="sm" variant="dashed" />
                <Button
                  fullWidth
                  variant="filled"
                  loading={isSubmitting}
                  type="submit"
                  className="mt-2 bg-red-400 hover:bg-primary"
                >
                  Absenden
                </Button>
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default LeadSignup;
