import { Link } from "react-router-dom";
import ImgLogo from "assets/imgs/svg/logo-dark.svg";
import { Divider } from "@mantine/core";

const Footer: React.FC = () => {
  return (
    <div className="bg-white">
      <Divider variant="dashed" />
      <ul className="max-w-7xl mx-auto px-8 flex items-center justify-between">
        <div className="flex sm:items-center flex-col sm:flex-row">
          <li>
            <Link className="text-red-500 font-semibold" to="/imprint">
              Impressum
            </Link>
          </li>
          <li className="sm:ml-3 mt-2 sm:mt-0">
            <Link
              className="text-red-500 font-semibold"
              to="/terms-and-conditions"
            >
              Allgemeine Geschäftsbedingungen
            </Link>
          </li>
        </div>
        <img src={ImgLogo} className="w-28" alt="logo" />
      </ul>
    </div>
  );
};

export default Footer;
