import { forwardRef, useCallback, useEffect, useState } from "react";
import {
  Group,
  MultiSelect,
  Text,
  Modal,
  Button,
  Divider,
  Switch,
  Tooltip,
  Checkbox,
  Pagination,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import SVGWave from "assets/imgs/png/wave.png";
import LeadInfoCard from "components/cards/lead-info-card";
import api from "utils/api";
import {
  FiBriefcase,
  FiMapPin,
  FiSearch,
  FiUserCheck,
  FiX,
} from "react-icons/fi";
import ModelTitle from "components/titles/model";
import classNames from "classnames";
import { showNotification } from "@mantine/notifications";
import { FaQuestion } from "react-icons/fa";

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  type: string;
}

interface ILead {
  img: string;
  name: string;
  profession: string[];
  id: string;
  phoneNumber: string;
  email: string;
  currentOccupation: string[];
  currentCity: string;
  location: string[];
  unmasked?: boolean;
}

const extractLeadData = (lead: any) => {
  return {
    id: lead.id,
    name: lead.name,
    location: lead.searchCity,
    profession: lead.profession,
    img: lead.avatar,
    phoneNumber: lead.phoneNumber,
    email: lead.email,
    currentOccupation: lead.currentOccupation,
    currentCity: lead.currentCity,
    unmasked: !lead.masked,
  };
};

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, type, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div className="flex items-center">
          {type === "location" ? <FiMapPin /> : <FiBriefcase />}
          <Text size="sm" className="font-semibold ml-2 text-gray-800">
            {label}
          </Text>
        </div>
      </Group>
    </div>
  )
);

const Leads: React.FC = () => {
  const [leads, setLeads] = useState<ILead[]>([]);
  const [selectedLead, setSelectedLead] = useState<
    (ILead & { i: number }) | null
  >(null);
  const [leadOpen, setLeadOpen] = useState(false);
  const [showOnlyAvailable, setShowOnlyAvailable] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] =
    useState(false);
  const [leadSearch, setLeadSearch] = useState<string[] | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounced] = useDebouncedValue(searchTerm, 200);
  const [searchTermOptions, setSearchTermOptions] = useState<any[]>([]);
  const [activePage, setPage] = useState<number>(1);
  const [leadsPerPage] = useState<number>(12);

  const fetchSearchOptions = useCallback(async () => {
    const { data } = await api.get("/company/search-leads-options", {
      params: { q: searchTermDebounced },
    });
    setSearchTermOptions((prev) => {
      return [...data.options, ...prev];
    });
  }, [searchTermDebounced]);

  const viewLead = useCallback(
    async (confirmed: boolean) => {
      const showConfirmationDialog = localStorage.getItem(
        "showConfirmationDialog"
      );
      const token = localStorage.getItem("token");
      if (
        !token ||
        showConfirmationDialog === "false" ||
        (confirmed && confirmationModalVisible)
      ) {
        setLeadOpen(true);
        setConfirmationModalVisible(false);
        if (!selectedLead) return;
        const lead = selectedLead as ILead;
        const indx = selectedLead.i as number;

        try {
          const { data } = await api.get(`/company/lead/${lead.id}`);
          const unmaskedLead = extractLeadData(data?.lead);
          const updatedLeads: ILead[] = [...leads];
          updatedLeads[indx] = {
            ...updatedLeads[indx],
            ...unmaskedLead,
            unmasked: true,
          };
          setSelectedLead((v: any) => {
            return { ...v, ...unmaskedLead, unmasked: true };
          });
          setLeads(updatedLeads);
        } catch (err: any) {
          showNotification({
            title:
              "Sie müssen angemeldet sein, um einen Lead anzeigen lassen zu können",
            color: "red",
            icon: <FiX />,
            message: err.response.data.error,
          });
        }
      } else if (
        !confirmed &&
        (showConfirmationDialog === null || showConfirmationDialog === "true")
      ) {
        setConfirmationModalVisible(true);
        setLeadOpen(false);
        return;
      }
    },
    [confirmationModalVisible, leads, selectedLead]
  );

  const fetchLeads = useCallback(async () => {
    const query: any = {};
    if (showOnlyAvailable) {
      query.available = true;
    }
    const leadSrchObj: Record<string, string> = {};

    if (leadSearch) {
      leadSearch.forEach((v) => {
        leadSrchObj[v] = v;
      });
    }
    const professions: string[] = [],
      locations: string[] = [];

    searchTermOptions.forEach((v) => {
      if (leadSrchObj[v.value]) {
        if (v.type === "profession") {
          professions.push(v.value);
        } else {
          locations.push(v.value);
        }
      }
    });

    query.profession = professions?.join("|");
    query.location = locations?.join("|");
    const token = localStorage.getItem("token");
    const { data } = await api.get(
      `company/search-leads${token ? "-available" : ""}`,
      { params: query }
    );

    const leads = data?.leads.map(extractLeadData);
    setLeads(leads || []);
  }, [leadSearch, searchTermOptions, showOnlyAvailable]);

  useEffect(() => {
    fetchLeads();
  }, [leadSearch, showOnlyAvailable, fetchLeads]);

  useEffect(() => {
    fetchSearchOptions();
  }, [searchTermDebounced, fetchSearchOptions]);

  const indexOfLastLead: number = activePage * leadsPerPage;
  const indexOfFirstLead: number = indexOfLastLead - leadsPerPage;
  const currentLeads: ILead[] = leads.slice(indexOfFirstLead, indexOfLastLead);
  const totalPage: number = Math.ceil(leads.length / leadsPerPage);

  return (
    <div className="relative pt-8 overflow-hidden">
      <div className="-z-10 md:h-56 h-60 top-0 w-full absolute bg-primary"></div>
      <img
        src={SVGWave}
        alt=""
        className="absolute -z-10 mt-52 md:mt-48 w-full"
      />
      <div
        className="max-w-7xl mx-auto px-8 pb-8"
        style={{ minHeight: "82vh" }}
      >
        <div className="rounded-lg bg-white p-4 shadow mb-8 inline-block">
          <div className="flex items-center mb-8">
            <MultiSelect
              radius={20}
              style={{ width: "26rem" }}
              placeholder="Bewerber:innen durchsuchen"
              variant="filled"
              searchable
              limit={10}
              itemComponent={SelectItem}
              onSearchChange={setSearchTerm}
              data={searchTermOptions}
              icon={<FiSearch />}
              onChange={setLeadSearch}
              clearable
              className="mr-2"
            />
            <Tooltip
              label={
                <p className="font-sans font-semibold">
                  Sie können mehrere Filter gleichzeitig anwenden, um nach den
                  passenden Bewerber:innen zu filtern.
                </p>
              }
              withArrow
              arrowSize={3}
              position="bottom"
            >
              <div className="bg-primary p-2 rounded-full text-white">
                <FaQuestion />
              </div>
            </Tooltip>
          </div>
          <Switch
            label={
              <h5 className="font-sans font-semibold text-gray-700 text-sm">
                Nach bereits angesehenen Leads filtern
              </h5>
            }
            checked={showOnlyAvailable}
            onChange={(e) => setShowOnlyAvailable(e.target.checked)}
            size="lg"
            radius="lg"
          />
        </div>
        <h2 className="text-4xl mb-10 text-gray-100 font-heading">
          <span className="font-bold text-white">{leads.length} </span>
          Bewerber:innen haben Interesse
        </h2>
        <div className="grid md:grid-cols-2 xl:grid-cols-3 grid-rows-1 gap-4 xl:gap-8 w-full">
          {currentLeads.map((lead, i) => {
            let leadIdx = i + leadsPerPage * (activePage - 1);
            return (
              <LeadInfoCard
                key={i}
                {...lead}
                profession={
                  typeof lead.profession === "string"
                    ? [lead.profession]
                    : lead.profession
                }
                onClick={() => {
                  setSelectedLead({ ...lead, i: leadIdx });
                  setLeadOpen(true);
                }}
              />
            );
          })}
        </div>

        {leads.length > leadsPerPage && (
          <div className="mt-16 flex justify-center">
            <Pagination
              color="red"
              radius="xl"
              onChange={setPage}
              page={activePage}
              total={totalPage}
            />
          </div>
        )}
      </div>

      <Modal
        opened={confirmationModalVisible}
        onClose={() => setConfirmationModalVisible(false)}
        centered
        title={<ModelTitle title="Bewerber:in anzeigen" />}
      >
        <div>
          <div className="flex flex-col items-center justify-center pt-4">
            <h6 className="font-semibold text-xl text-gray-700 font-heading">
              Durch das Klicken auf “Bewerber:in anzeigen” entstehen Kosten.
              Möchten Sie fortfahren?
            </h6>
          </div>
          <Divider variant="dashed" className="my-4" />
          <Checkbox
            onChange={(e) =>
              localStorage.setItem(
                "showConfirmationDialog",
                String(!e.target.checked)
              )
            }
            label={
              <p className="font-sans font-semibold text-gray-700">
                Diese Meldung zukünftig nicht mehr anzeigen.
              </p>
            }
          />
          <Divider variant="dashed" className="my-4" />
          <Group position="center">
            <Button
              className="bg-red-400 hover:bg-primary"
              onClick={() => viewLead(true)}
              disabled={selectedLead?.unmasked}
            >
              Ja
            </Button>
            <Button
              variant="outline"
              color="gray"
              onClick={() => setConfirmationModalVisible(false)}
            >
              Nein
            </Button>
          </Group>
        </div>
      </Modal>
      <Modal
        opened={leadOpen}
        onClose={() => setLeadOpen(false)}
        centered
        title={<ModelTitle title="Bewerber:in anzeigen" />}
      >
        <div className="flex flex-col items-center justify-center pt-4">
          <div className="bg-primary rounded-full mr-4 h-20 w-20 overflow-hidden shadow-lg flex items-center justify-center">
            {selectedLead?.img ? (
              <img
                src={selectedLead?.img}
                alt=""
                className="w-full object-cover content-center"
              />
            ) : (
              <div className="p-4">
                <FiUserCheck className="text-4xl text-white" />
              </div>
            )}
          </div>
          <h6 className="font-bold mt-2 text-2xl text-gray-800 font-heading whitespace-nowrap">
            {selectedLead?.name}
          </h6>
          <div className="flex items-center font-semibold -ml-8">
            <span className="ml-2 text-gray-700">{selectedLead?.email}</span>
          </div>
          <div className="flex items-center mt-2 font-semibold -ml-8">
            <span className="ml-2 text-gray-700">
              {selectedLead?.phoneNumber}
            </span>
          </div>
          <Divider variant="dashed" className="my-2" />
          {Boolean(selectedLead?.profession.length) && (
            <>
              <div className="flex flex-col mt-2 items-center">
                {selectedLead?.profession.map((profession) => (
                  <h6 className="text-gray-700" key={profession}>
                    {profession}
                  </h6>
                ))}
              </div>
              <Divider variant="dashed" className="my-2" />
            </>
          )}
          {selectedLead?.currentCity && (
            <div className="flex items-center mt-2 -ml-8">
              <span className="">Aktueller wohnort:</span>
              <span className="ml-2 font-semibold text-gray-700">
                {selectedLead?.currentCity}
              </span>
            </div>
          )}
          <div className="-ml-8 flex items-center flex-col w-full max-w-xs mt-3">
            {selectedLead?.location.map((location: string, i: number) => {
              return (
                <div
                  key={location}
                  className={classNames("flex justify-center", {
                    "mt-2": i !== 0,
                  })}
                >
                  <FiMapPin className="mr-2 text-xl text-red-500" />
                  <span className="font-semibold mr-2 inline-block text-ellipsis overflow-hidden text-gray-700 whitespace-nowrap text-sm">
                    {location}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <Divider variant="dashed" className="my-4" />
        <Group position="center">
          <Button
            className="bg-red-400 hover:bg-primary"
            onClick={() => viewLead(false)}
            disabled={selectedLead?.unmasked}
          >
            Bewerber:in anzeigen
          </Button>
          <Button
            variant="outline"
            color="gray"
            onClick={() => setLeadOpen(false)}
          >
            Zurück
          </Button>
        </Group>
      </Modal>
    </div>
  );
};

export default Leads;
