import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import cn from "classnames";
import { FiPower, FiUser } from "react-icons/fi";
import SVGLogo from "assets/imgs/svg/logo.svg";
import { useStore } from "lib/store";

const Navbar: React.FC = () => {
  const { isAuthenticated, setIsAuthenticated } = useStore();

  const navLinks = [
    {
      title: "Registrierung für Unternehmen",
      path: `/${isAuthenticated ? "leads" : "signup"}`,
    },
    {
      title: "Login für Unternehmen",
      path: "/login",
      hidden: isAuthenticated,
    },
    {
      title: "Bewerber:innen",
      path: "/ausbildung-signup",
      hidden: isAuthenticated,
    },
  ];
  const userDropdownLinks = [
    { title: "Unternehmensprofil", path: "/profile", icon: FiUser },
    { title: "Ausloggen", path: "/login", icon: FiPower },
  ];
  const location = useLocation();
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);

  return (
    <nav className="bg-gray-800 fixed top-0 left-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                className="hidden h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-center sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <img
                className="block"
                style={{ width: "5rem" }}
                src={SVGLogo}
                alt="Workflow"
              />
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {navLinks.map((link) => {
                  if (link.hidden) return null;

                  return (
                    <Link
                      key={link.path}
                      to={link.path}
                      className={cn(
                        [
                          location.pathname === link.path
                            ? "bg-primary text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        ],
                        "px-3 py-2 rounded-md text-sm font-medium"
                      )}
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="ml-3 relative">
              {isAuthenticated && (
                <div>
                  <button
                    type="button"
                    className="bg-gray-800 flex text-sm rounded-full focus:outline-none"
                    onClick={() => setIsUserDropdownOpen(!isUserDropdownOpen)}
                  >
                    <span className="sr-only">Open user menu</span>
                    <div className="h-10 w-10 rounded-full overflow-hidden bg-gray-100 flex items-center justify-center">
                      <FiUser className="text-xl" />
                    </div>
                  </button>
                </div>
              )}

              {isUserDropdownOpen && (
                <div
                  className="absolute right-0 mt-2 w-80 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                >
                  {userDropdownLinks.map((link) => (
                    <Link
                      key={link.path}
                      to={link.path}
                      role="menuitem"
                      onClick={() => {
                        if (link.path === "/login") {
                          localStorage.clear();
                          setIsAuthenticated(false);
                        }
                        setIsUserDropdownOpen(false);
                      }}
                      className="px-4 py-2 text-sm text-gray-700 flex items-center"
                    >
                      {<link.icon className="mr-2" />}
                      {link.title}
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isMobileNavOpen && (
        <div className="sm:hidden">
          <div className="px-2 pt-2 pb-3 space-y-1">
            {navLinks.map((link) => {
              if (link.hidden) return null;

              return (
                <Link
                  key={link.path}
                  to={link.path}
                  className={cn(
                    [
                      location.pathname === link.path
                        ? "bg-primary text-white"
                        : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    ],
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                >
                  {link.title}
                </Link>
              );
            })}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
