import classNames from "classnames";
import { FiMapPin, FiUserCheck } from "react-icons/fi";

interface LeadInfoCardProps {
  name: string;
  location: string[];
  profession: string[];
  onClick: () => void;
  img?: string;
}

const LeadInfoCard: React.FC<LeadInfoCardProps> = (props) => {
  return (
    <div
      style={{ boxShadow: "0 0 1.6rem rgba(0,0,0,0.1)" }}
      className="bg-white w-full sm:w-auto cursor-pointer rounded-md py-6 px-8 flex items-center justify-start"
      onClick={props.onClick}
    >
      <div className="flex items-center">
        <div className="bg-primary flex-shrink-0 rounded-full mr-4 h-20 w-20 overflow-hidden shadow-lg flex items-center justify-center">
          {props.img ? (
            <img
              src={props.img}
              alt=""
              className="w-full object-cover content-center"
            />
          ) : (
            <div className="p-4">
              <FiUserCheck className="text-4xl text-white" />
            </div>
          )}
        </div>
        <div>
          <h6 className="font-bold text-ellipsis overflow-hidden w-32 text-2xl text-gray-800 font-heading whitespace-nowrap">
            {props.name}
          </h6>
          <div className="flex flex-col">
            {props.profession.map((profession) => (
              <h6 className="text-gray-700" key={profession}>
                {profession}
              </h6>
            ))}
          </div>
          <div className="flex justify-start w-full max-w-xs mt-3 flex-wrap">
            {props.location.map((location: string, i: number) => {
              return (
                <div
                  key={location}
                  className={classNames("flex items-start justify-start mb-2")}
                >
                  <FiMapPin className="mr-2 text-xl text-red-500" />
                  <span className="font-semibold mr-2 inline-block text-ellipsis overflow-hidden w-16 text-gray-700 whitespace-nowrap text-sm">
                    {location}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadInfoCard;
