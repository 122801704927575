const Imprint: React.FC = () => {
  return (
    <div className="relative overflow-hidden h-screen">
      <div className="max-w-7xl mx-auto px-8 py-8">
        <h1 className="text-gray-800 font-heading text-4xl my-10">Impressum</h1>
        <div>
          <h2 className="font-heading text-gray-700 text-2xl">Kontakt:</h2>
          <a
            href="mailto:support@moberries.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary mt-2 block font-semibold text-sm"
          >
            support@moberries.com
          </a>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            T.: +49 (0)30 275964 00
          </p>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            Press:{" "}
            <a
              href="mailto:press@moberries.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary"
            >
              press@moberries.com
            </a>
          </p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Adresse - Angaben gemäß § 5 TMG:
          </h2>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            MoBerries GmbH
          </p>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            Schwedter Str 9A
          </p>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            10119 Berlin
          </p>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            Managing Director - Terence Hielscher
          </p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Registereintrag:
          </h2>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            Amtsgericht Berlin (Charlottenburg) HRB 165716 B
          </p>
          <h2 className="font-heading text-gray-700 text-2xl mt-4">
            Umsatzsteuer-ID:
          </h2>
          <p className="text-sm font-semibold text-gray-700 mt-1">
            DE298995562
          </p>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
