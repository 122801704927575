import { Routes, Route } from "react-router-dom";
import Homepage from "pages/homepage";
import Leads from "pages/leads";
import Login from "pages/login";
import Signup from "pages/signup";
import Billing from "pages/billing";
import VerifyEmail from "pages/verify-email";
import TermsAndConditions from "pages/terms-and-conditions";
import Imprint from "pages/imprint";

const PageRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/leads" element={<Leads />} />
      <Route path="/ausbildung-signup" element={<Homepage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/profile" element={<Billing />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/imprint" element={<Imprint />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
    </Routes>
  );
};

export default PageRoutes;
