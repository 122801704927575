import BGImg from "assets/imgs/company_signup.jpg";
import LoginForm from "components/login/form";

const Login: React.FC = () => {
  return (
    <div
      style={{
        backgroundImage: `linear-gradient(to right bottom, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, .7)), url(${BGImg})`,
      }}
      className="bg-center bg-cover flex min-h-screen -mt-16 items-center justify-end relative"
    >
      <div className="w-full md:w-3/5 flex items-center justify-end">
        <LoginForm />
      </div>
    </div>
  );
};

export default Login;
