import { Timeline, Text, Divider, Tabs, Group, Button } from "@mantine/core";
import SVGWave from "assets/imgs/png/wave.png";
import FormInput from "components/form-input";
import { useEffect, useMemo, useState } from "react";
import { FaEuroSign, FaRegBuilding } from "react-icons/fa";
import { FiEdit, FiGlobe, FiMap, FiPhone, FiUserCheck } from "react-icons/fi";
import api from "utils/api";

const currencyIcons = {
  EUR: <FaEuroSign />,
};

interface ICredit {
  name: string;
  email: string;
  creditsUsed: number;
  viewedOn: string;
  id: string;
  currencyCode: keyof typeof currencyIcons;
}

const Billing: React.FC = () => {
  const [credits, setCredits] = useState<ICredit[]>([]);
  const [company, setCompany] = useState<any>({});
  const [editOpen, setEditOpen] = useState(false);
  const [editCompany, setEditCompany] = useState<any>(company);

  const totalCredits = useMemo(() => {
    return credits.reduce((acc, curr) => acc + Number(curr.creditsUsed), 0);
  }, [credits]);

  const fetchUsedCredits = async () => {
    const { data } = await api.get("/company/credits");

    setCredits(data.data);
    setCompany(data.company);
  };

  const onSave = async () => {
    setEditOpen(false);
    await api.put("/company", editCompany);
    setCompany(editCompany);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setEditCompany({ ...editCompany, [name]: value });
  };

  useEffect(() => {
    setEditCompany(company);
  }, [company]);

  useEffect(() => {
    fetchUsedCredits();
  }, []);

  return (
    <div className="relative pt-8" style={{ minHeight: "78vh" }}>
      <div className="-z-10 h-36 top-0 w-full absolute bg-primary"></div>
      <img
        src={SVGWave}
        alt=""
        className="absolute -z-10 lg:mt-16 mt-28 w-full"
      />
      <div
        className="max-w-7xl mx-auto px-8 pb-8 flex items-center justify-center"
        style={{ minHeight: "28rem" }}
      >
        <div
          style={{ minWidth: "20rem" }}
          className="bg-white pt-2 rounded-lg border border-gray-200 shadow-md"
        >
          <Tabs tabPadding="sm">
            <Tabs.Tab
              label={
                <p className="font-heading text-gray-700 text-lg">
                  Unternehmensprofil
                </p>
              }
            >
              {!editOpen && Boolean(Object.keys(company).length) && (
                <div>
                  <div className="flex justify-end px-4 pt-4">
                    <button
                      id="dropdownButton"
                      onClick={() => setEditOpen(true)}
                      className="hidden sm:inline-block text-gray-500 hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg text-sm p-1.5"
                      type="button"
                    >
                      <FiEdit className="text-2xl text-gray-500" />
                    </button>
                  </div>

                  <div className="flex flex-col items-center pb-10 px-16 -mt-8">
                    <img
                      className="mb-3 w-24 h-24 rounded-full shadow-lg"
                      src="https://www.gravatar.com/avatar/96325a05fe5838134116a3d1d67aaf97?d=retro"
                      alt=""
                    />
                    <h5 className="mb-1 font-heading font-bold text-2xl text-primary">
                      {`${company?.firstName} ${company?.lastName}`}
                    </h5>
                    <h5 className="mb-1 font-heading flex items-center font-bold text-xl text-gray-700">
                      <FaRegBuilding className="mr-2" /> {company?.companyName}
                    </h5>
                    <span className="text-sm text-gray-600 font-semibold">
                      {company?.email}
                    </span>
                    {company?.website && (
                      <span className="text-sm block my-2 text-blue-500 font-semibold">
                        {company?.website}
                      </span>
                    )}
                    <span className="text-sm text-gray-600 font-semibold">
                      {company?.location}
                    </span>
                  </div>
                </div>
              )}
              {editOpen && (
                <div className="p-4 -mt-4">
                  <FormInput
                    placeholder="Vorname"
                    label="Vorname"
                    name="firstName"
                    value={editCompany.firstName || ""}
                    onChange={handleInputChange}
                    icon={<FiUserCheck />}
                  />
                  <Divider my="sm" variant="dashed" />
                  <FormInput
                    placeholder="Nachname"
                    label="Nachname"
                    onChange={handleInputChange}
                    name="lastName"
                    value={editCompany.lastName || ""}
                    icon={<FiUserCheck />}
                  />
                  <Divider my="sm" variant="dashed" />
                  <FormInput
                    placeholder="Penta"
                    label="Firmenname"
                    onChange={handleInputChange}
                    name="companyName"
                    value={editCompany.companyName || ""}
                    icon={<FaRegBuilding />}
                  />
                  <Divider my="sm" variant="dashed" />
                  <FormInput
                    placeholder="Firmenadresse"
                    label="Firmenadresse"
                    onChange={handleInputChange}
                    name="location"
                    value={editCompany.location || ""}
                    icon={<FiMap />}
                  />
                  <Divider my="sm" variant="dashed" />
                  <FormInput
                    placeholder="Telefonnummer"
                    label="Telefonnummer"
                    onChange={handleInputChange}
                    name="phoneNumber"
                    value={editCompany.phoneNumber || ""}
                    icon={<FiPhone />}
                  />
                  <Divider my="sm" variant="dashed" />
                  <FormInput
                    placeholder="Unternehmenswebsite"
                    label="Unternehmenswebsite"
                    onChange={handleInputChange}
                    name="website"
                    value={editCompany.website || ""}
                    icon={<FiGlobe />}
                  />
                  <Divider variant="dashed" className="my-4" />
                  <Group position="center">
                    <Button
                      onClick={onSave}
                      fullWidth
                      className="bg-red-400 hover:bg-primary"
                    >
                      Speichern
                    </Button>
                    <Button
                      onClick={() => setEditOpen(false)}
                      fullWidth
                      variant="outline"
                      color="gray"
                    >
                      Zurück
                    </Button>
                  </Group>
                </div>
              )}
            </Tabs.Tab>
            <Tabs.Tab
              label={
                <p className="font-heading text-gray-700 text-lg">Historie</p>
              }
            >
              <div>
                <p className="text-lg ml-8 text-red-500 font-semibold font-heading flex items-center">
                  Fälliger Gesamtbetrag:
                  <span className="flex items-center text-gray-600 ml-2">
                    <FaEuroSign /> {totalCredits.toFixed(2)}
                  </span>
                </p>
                <Divider my="sm" variant="dashed" />
                <div className="mt-4 px-8 pb-8">
                  <p className="text-xl mb-4 text-blue-500 font-semibold font-heading flex items-center">
                    Historie
                  </p>
                  <Timeline bulletSize={24} lineWidth={2}>
                    {credits.map((credit) => (
                      <Timeline.Item
                        key={credit.id}
                        title={credit.name}
                        bullet={<FiUserCheck size={12} />}
                        lineVariant="dashed"
                      >
                        <Text size="sm" mt={4}>
                          {credit.email}
                        </Text>
                        <Text
                          color="dimmed"
                          size="sm"
                          className="flex items-center"
                        >
                          Benutzte Credits: {currencyIcons[credit.currencyCode]}{" "}
                          {credit.creditsUsed}
                        </Text>
                        <Text size="xs" mt={4}>
                          {credit.viewedOn}
                        </Text>
                      </Timeline.Item>
                    ))}
                  </Timeline>
                </div>
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Billing;
