import Footer from "components/footer";
import Navbar from "components/navbar";

const Layout: React.FC = (props) => {
  return (
    <>
      <Navbar />
      <div className="pt-16 overflow-x-hidden">{props.children}</div>
      <Footer />
    </>
  );
};

export default Layout;
