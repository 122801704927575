import { BrowserRouter as Router } from "react-router-dom";
import Routes from "routes";
import Layout from "components/layout";
import { inIframe } from "utils/common";
import { NotificationsProvider } from "@mantine/notifications";
import Homepage from "pages/homepage";
import Auth from "components/auth";

const IFrame: React.FC = () => {
  return <Homepage iframe />;
};

function App() {
  const isInIframe = inIframe();

  if (isInIframe) return <IFrame />;

  return (
    <Router>
      <NotificationsProvider>
        <Auth>
          <Layout>
            <Routes />
          </Layout>
        </Auth>
      </NotificationsProvider>
    </Router>
  );
}

export default App;
