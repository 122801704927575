import { useEffect, useState } from "react";
import api from "utils/api";

interface ICity {
  label: string;
  value: string;
  city: object;
}

export const useSearchCity = (searchCity: string): [ICity[], boolean] => {
  const [cities, setCities] = useState<ICity[]>([]);
  const [loadingCities, setLoadingCities] = useState(false);

  useEffect(() => {
    if (!searchCity) return;
    setLoadingCities(true);
    api
      .get("/lead/cities", {
        params: {
          q: searchCity,
        },
      })
      .then(({ data }) => {
        setCities((cities) => {
          return [...data.cities, ...cities];
        });
        setLoadingCities(false);
      });
  }, [searchCity]);

  return [cities, loadingCities];
};
